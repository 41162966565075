import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import updateAssociations from "../services/bookingResourceSkusService/updateAssociations";
import removeAssociations from "../services/bookingResourceSkusService/removeAssociations";
import fetchFlights from "../services/bookingResourceSkuFlightsService/fetchFlights";

export default class extends Controller {
  static targets = [
    "flightList",
    "optionsModal",
    "pickupDropoffMessage",
    "pickupButton",
    "dropoffButton",
    "cancelButton",
    "flightSelectionModal",
    "flightCancelButton",
  ];

  connect() {
    this.boundHandleDrop = this.onDragDrop.bind(this);
    this.element.addEventListener("drag-drop:ended", this.boundHandleDrop);
  }

  disconnect() {
    this.element.removeEventListener("drag-drop:ended", this.boundHandleDrop);
  }

  onDragDrop(event) {
    const { draggedItem, dropTarget } = event.detail;

    const draggedElement = draggedItem.querySelector(
      "[data-resource-sku-handle]"
    );

    const targetElement = dropTarget.closest("[data-resource-sku-handle]");

    if (!draggedElement || !targetElement) return;

    const draggedData = {
      id: draggedElement.dataset.bookingResourceSkuId,
      type: draggedElement.dataset.resourceSkuType,
      handle: draggedElement.dataset.resourceSkuHandle,
      resourceType: draggedElement.dataset.resourceSkuType,
      associatedBookingResourceSkuIdFromInverse:
        draggedElement.dataset.associatedBookingResourceSkuIdFromInverse,
      element: draggedElement,
      event,
    };

    const targetData = {
      id: targetElement.dataset.bookingResourceSkuId,
      type: targetElement.dataset.resourceSkuType,
      handle: targetElement.dataset.resourceSkuHandle,
      element: targetElement,
      bookingResourceSkuTransferFlightId:
        targetElement.dataset.bookingResourceSkuTransferFlightId,
      bookingResourceSkuTransferAccommodationId:
        targetElement.dataset.bookingResourceSkuTransferAccommodationId,
      event,
    };

    if (
      !this.constructor.validBookingResourceSkuAssociation(
        draggedData,
        targetData
      )
    ) {
      this.constructor.showInvalidDropIndicator(targetData.element);
      return;
    }

    if (draggedData.type === "transfer") {
      this.handleAssociation(draggedData, targetData);
    } else {
      this.showOptionsModal(draggedData, targetData);
    }
  }

  showOptionsModal(draggedData, targetData) {
    if (!this.pickupDropoffMessageTarget.dataset.originalMessage) {
      this.pickupDropoffMessageTarget.dataset.originalMessage =
        this.pickupDropoffMessageTarget.textContent;
    }

    this.pickupDropoffMessageTarget.textContent =
      this.pickupDropoffMessageTarget.dataset.originalMessage.replace(
        "$RESOURCE_TYPE",
        draggedData.resourceType
      );

    const handleOptionClick = (option) => {
      this.optionsModalTarget.classList.add("hidden");
      if (option === "cancel") {
        return;
      }

      if (draggedData.type === "flight") {
        this.showFlightSelectionModal(draggedData, targetData, option);
      } else {
        this.handleAssociation(draggedData, targetData, option);
      }
    };

    this.pickupButtonTarget.onclick = () => handleOptionClick("pickup");
    this.dropoffButtonTarget.onclick = () => handleOptionClick("dropoff");
    this.cancelButtonTarget.onclick = () => handleOptionClick("cancel");

    this.optionsModalTarget.classList.remove("hidden");
  }

  showFlightSelectionModal(draggedData, targetData, transferDirection) {
    fetchFlights({
      bookingResourceSkuId: draggedData.id,
      success: (response) => {
        const { flights } = response;

        // Clear existing flights
        this.flightListTarget.innerHTML = "";
        flights.forEach((flightData) =>
          this.addFlightRow(
            draggedData,
            targetData,
            flightData,
            transferDirection
          )
        );

        this.flightCancelButtonTarget.onclick = () => {
          this.flightSelectionModalTarget.classList.add("hidden");
        };

        this.flightSelectionModalTarget.classList.remove("hidden");
      },
      error: () => {},
    });
  }

  addFlightRow(draggedData, targetData, flightData, transferDirection) {
    const template = document.getElementById("flight-row-template");
    const row = template.content.cloneNode(true);

    const tr = row.querySelector("tr");
    tr.dataset.bookingResourceSkuFlightId =
      flightData.bookingResourceSkuFlightId;

    row.querySelector("[data-airline-code]").textContent =
      flightData.airlineCode;
    row.querySelector("[data-flight-number]").textContent =
      flightData.flightNumber;
    row.querySelector("[data-departure-airport]").textContent =
      flightData.departureAirport;
    row.querySelector("[data-arrival-airport]").textContent =
      flightData.arrivalAirport;
    row.querySelector("[data-departure-time]").textContent = new Date(
      flightData.departureAt
    ).toLocaleString();
    row.querySelector("[data-arrival-time]").textContent = new Date(
      flightData.arrivalAt
    ).toLocaleString();

    const selectBtn = row.querySelector("[data-select-flight]");
    selectBtn.onclick = () => {
      this.flightSelectionModalTarget.classList.add("hidden");
      this.handleAssociation(
        draggedData,
        targetData,
        transferDirection,
        flightData
      );
    };

    this.flightListTarget.appendChild(row);
  }

  handleAssociation(
    draggedData,
    targetData,
    transferDirection = null,
    flightData = null
  ) {
    const associations = {
      transfer: () => {
        this.createTransferParentChildAssociation(draggedData, targetData);
      },
      accommodation: () => {
        this.createTransferAccomodationAssociation(
          draggedData,
          targetData,
          transferDirection
        );
      },
      flight: () => {
        this.createTransferFlightAssociation(
          draggedData,
          targetData,
          transferDirection,
          flightData
        );
      },
    };

    associations[draggedData.type]();
  }

  createTransferAccomodationAssociation(
    draggedData,
    targetData,
    transferDirection
  ) {
    const bookingResourceSkuId = targetData.id;
    const associatedBookingResourceSkuId = draggedData.id;
    const newBookingResourceSkuId = null;

    this.createTransferAssociation(
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      newBookingResourceSkuId,
      "accommodation",
      draggedData,
      targetData,
      transferDirection
    );
  }

  createTransferFlightAssociation(
    draggedData,
    targetData,
    transferDirection,
    flightData
  ) {
    const bookingResourceSkuId = targetData.id;
    const associatedBookingResourceSkuId = draggedData.id;
    const newBookingResourceSkuId = null;

    this.createTransferAssociation(
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      newBookingResourceSkuId,
      "flight",
      draggedData,
      targetData,
      transferDirection,
      flightData
    );
  }

  createTransferParentChildAssociation(draggedData, targetData) {
    let bookingResourceSkuId = null;
    let newBookingResourceSkuId = null;

    if (draggedData.associatedBookingResourceSkuIdFromInverse) {
      bookingResourceSkuId =
        draggedData.associatedBookingResourceSkuIdFromInverse;
      newBookingResourceSkuId = targetData.id;
    } else {
      bookingResourceSkuId = targetData.id;
    }
    const associatedBookingResourceSkuId = draggedData.id;

    this.createTransferAssociation(
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      newBookingResourceSkuId,
      "parent_child",
      draggedData,
      targetData
    );
  }

  createTransferAssociation(
    bookingResourceSkuId,
    associatedBookingResourceSkuId,
    newBookingResourceSkuId,
    associationType,
    draggedData,
    targetData,
    transferDirection = null,
    flightData = null
  ) {
    this.constructor.addLoadingOverlay(targetData.element);

    updateAssociations({
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      newBookingResourceSkuId,
      associationType,
      transferDirection,
      bookingResourceSkuFlightId: flightData?.bookingResourceSkuFlightId,
      success: async () => {
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(targetData.element);
      },
      error: async () => {
        draggedData.event.from.appendChild(draggedData.element);
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(targetData.element);
      },
    });
  }

  static validBookingResourceSkuAssociation(draggedData, targetData) {
    if (!targetData.handle.startsWith("transfer-person")) {
      return false;
    }

    if (draggedData.type === "transfer") {
      return draggedData.handle.startsWith("transfer-other");
    }

    if (draggedData.type === "flight") {
      return [null, undefined, ""].includes(
        targetData.bookingResourceSkuTransferFlightId
      );
    }

    if (draggedData.type === "accommodation") {
      return [null, undefined, ""].includes(
        targetData.bookingResourceSkuTransferAccommodationId
      );
    }

    return false;
  }

  static updateUI() {
    return Turbo.visit(window.location.href, {
      frame: "booking_resource_skus",
    });
  }

  static addLoadingOverlay(target) {
    const targetFrame = target.closest("turbo-frame");
    targetFrame.style.position = "relative";

    const template = document.getElementById("loading-overlay-template");
    const overlay = template.content.cloneNode(true).firstElementChild;
    targetFrame.appendChild(overlay);
  }

  static removeLoadingOverlay(target) {
    const targetFrame = target.closest("turbo-frame");
    const overlay = targetFrame.querySelector(".absolute");
    if (overlay) overlay.remove();
  }

  static showInvalidDropIndicator(element) {
    const template = document.getElementById("invalid-drop-indicator");
    const indicator = template.content.cloneNode(true);

    element.classList.add("relative");
    element.appendChild(indicator);

    setTimeout(() => {
      element.classList.remove("relative");
      element.removeChild(element.lastElementChild);
    }, 1000);
  }

  breakRelationship(event) {
    const {
      associatedBookingResourceSkuIdFromInverse: bookingResourceSkuId,
      bookingResourceSkuId: associatedBookingResourceSkuId,
      associationType,
      bookingResourceSkuFlightId,
    } = event.currentTarget.dataset;

    const targetElement = event.currentTarget.closest(
      "[data-resource-sku-handle]"
    );

    this.constructor.addLoadingOverlay(targetElement);

    removeAssociations({
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      associationType,
      bookingResourceSkuFlightId,
      success: async () => {
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(targetElement);
      },
      error: async () => {
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(targetElement);
      },
    });
  }
}
